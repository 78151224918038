import { Box, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import americaFlag from "../../assets/images/flags/america.png";
import egyptFlag from "../../assets/images/flags/egypt.png";
import russiaFlag from "../../assets/images/flags/russia.png";
import turkeyFlag from "../../assets/images/flags/turkey.png";
import franceFlag from "../../assets/images/flags/france.png";
import { saveLanguage } from "../../store/slices/auth";
import classes from "../layout/AuthLayout.module.scss";
import { useState } from "react";
import { IconButton } from "@mui/material"
import {BiChevronDown} from "react-icons/bi"
import Menu from '@mui/material/Menu';
 
const languages = [
  { value: "en", label: "English", flag: americaFlag },
  { value: "ar", label: "العربية", flag: egyptFlag },
  { value: "tu", label: "Türkçe", flag: turkeyFlag },
  { value: "ru", label: "русский", flag: russiaFlag },
  { value: "fr", label: "française", flag: franceFlag },
];


const LanguageDropdown = () => {
  const dispatch = useDispatch();
  const {language} = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);

  const handleClick = (event) => {
      setAnchorEl(event?.currentTarget)
      setOpenEvent(!openEvent)
  };

  const selectLanguageHandler = (lang) => {
    dispatch(saveLanguage({ language: lang }));
    setAnchorEl(false)
    setOpenEvent(false)
  };

  return (
    <div className={classes.lang}>
      <IconButton
        // size="large"
        aria-label="menu"
        color="inherit"
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ borderRadius: "10px" }}
      >
     
        {language === "en" && (
          <div className="d-flex gap-1 justify-content-center align-items-center">
            <img src={americaFlag} alt="flag" />{" "}
            {/* <span style={{ fontSize: "16px" }}>English</span> */}
          </div>
        )}
        {language === "ar" && (
          <div className="d-flex gap-1 justify-content-center align-items-center">
            <img src={egyptFlag} alt="flag" />{" "}
          </div>
        )}
        {language === "ru" && (
          <div className="d-flex gap-1 justify-content-center align-items-center">
            <img src={russiaFlag} alt="flag" />{" "}
          </div>
        )}
        {language === "tu" && (
          <div className="d-flex gap-1 justify-content-center align-items-center">
            <img src={turkeyFlag} alt="flag" />{" "}
          </div>
        )}
        {language === "fr" && (
          <div className="d-flex gap-1 justify-content-center align-items-center">
            <img src={franceFlag} alt="flag" />{" "} 
          </div>
          
        )}
        <BiChevronDown/>
      </IconButton>
    
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={openEvent}
        onClose={handleClick}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        sx={{
          "& .MuiMenu-paper": {
            width: "200px",
            right: 0,
            top: "50px !important",
          },
          "& .MuiList-padding": {
            p: "15px",
          },
        }}
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.value}
            sx={{
              pt: 1,
              pb: 1,
              borderRadius: "0px",
            }}
            onClick={() => selectLanguageHandler(lang.value)}
          >
            <Box display="flex" alignItems="center">
              <img src={lang.flag} alt="flag" />
              <Box
                sx={{
                  ml: 1,
                }}
              >
                <Typography
                  variant="h5"
                  noWrap
                  sx={{
                    width: "240px",
                    fontSize: "1rem",
                  }}
                >
                  {lang.label}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </div>

  );
};

export default LanguageDropdown;
