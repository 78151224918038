import { gql } from "@apollo/client";

export const LOGIN = gql `
mutation AdminLogin($email: String!, $password: String!) {
  adminLogin(email: $email, password: $password) {
    code
    success
    message
    token
    data {
      _id
      fullName
      email
      webFirebaseToken
    }
  }
}
`

export const LOGOUT = gql `
mutation AdminLogout {
  adminLogout {
    code
    success
    message
  }
}
`