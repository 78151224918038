import { Outlet } from "react-router-dom"
import classes from './AuthLayout.module.scss'
import logo from '../../assets/images/logo.png'
import layout from "../../assets/images/layout.png"
import { useSelector } from "react-redux"
import words from "../../assets/words"
import LanguageDropdown from "../general/LanguageDropdown"

const AuthLayout = () => {
    const { language } = useSelector(state => state.auth);

    return (
        <div className={classes.layout} dir={language === "ar" ? "rtl" : "ltr"}>
            <div className={classes.leftSide}>
                <div style={{paddingRight: language === "ar" && "10px"}}>
                    <img src={logo} alt='logo pic' />
                    <h3>{words.WELCOME_TO[language]}<span> {words.SWIPY[language]}</span>  </h3>
                    <p>{words.SWIPY_DESC[language]} </p>
                </div>
                <img className={classes.authImg} src={layout} alt="layout pic" />
            </div>
            <div className={classes.rightSide}>
                <LanguageDropdown/>
                <div className={classes.outlet}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default AuthLayout