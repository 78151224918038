import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import AuthLayout from "../components/layout/AuthLayout";
import Spinner from "react-bootstrap/esm/Spinner";
import MainLayout from "../components/layout/MainLayout";
import Error from "../components/layout/Error";
import { loader, tokenLoader } from "../utils/auth";


const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        resolve(component);
      })
      .catch((error) => {
        window.location.reload();
        reject(error); // there was an error
      });
  });
};

const LoginPage = lazy(() =>
  lazyRetry(() => import("../pages/auth/LoginPage"))
);

const ClientsPage = lazy(() =>
  lazyRetry(() => import('../pages/main/clients/ClientsPage'))
)
const ClientsDetails = lazy(() =>
  lazyRetry(() => import('../pages/main/clients/ClientsDetailsPage'))
)

const PromotionsPage = lazy(() =>
  lazyRetry(() => import('../pages/main/promotions/PromotionsPage'))
)
const TicketsPage = lazy(() =>
  lazyRetry(() => import('../pages/main/tickets/TicketsPage'))
)
const PromoCodePage = lazy(() =>
  lazyRetry(() => import('../pages/main/promotions/PromoCodePage'))
)
const PromoCodeAffiliatePage = lazy(() =>
  lazyRetry(() => import('../pages/main/promotions/PromoCodeAffiliatePage'))
)



const Loading = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }}
  >
    <Spinner as="span" animation="border" variant="black" />
  </div>
);

export const router = createBrowserRouter([
  {
    path: "auth",
    errorElement: <Error />,
    loader: tokenLoader,
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={Loading}>
            <LoginPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "",
    errorElement: <Error />,
    loader: loader,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={Loading}>
            <ClientsPage />
          </Suspense>
        ),
      },
      {
        path: "promotions",
        element: (
          <Suspense fallback={Loading}>
            <PromotionsPage />
          </Suspense>
        ),
      },
      {
        path: "tickets",
        element: (
          <Suspense fallback={Loading}>
            <TicketsPage />
          </Suspense>
        ),
      },
      {
        path: "promo-code",
        element: (
          <Suspense fallback={Loading}>
            <PromoCodePage />
          </Suspense>
        ),
      },
      {
        path: "promo-code-affiliate",
        element: (
          <Suspense fallback={Loading}>
            <PromoCodeAffiliatePage />
          </Suspense>
        ),
      },
      {
        path: "promo-code/:id",
        element: (
          <Suspense fallback={Loading}>
            <PromoCodePage />
          </Suspense>
        ),
      },
   
      {
        path: "client-details/:id",
        element: (
          <Suspense fallback={Loading}>
            <ClientsDetails />
          </Suspense>
        ),
      },
    ]
  },
  {
    path: "*",
    errorElement: <Error />,
    element: <Error />
  }
]);