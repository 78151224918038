import { createSlice } from "@reduxjs/toolkit";

export const home = createSlice({
    name: 'home',
    initialState: {
        cardData: JSON.parse(sessionStorage.getItem("cardData")) || null,
        currebtStep: JSON.parse(sessionStorage.getItem("currebtStep")) || 0,
        cardId: sessionStorage.getItem("cardId") || null,
        designId: sessionStorage.getItem("designId") || null,
    },
    reducers: {
        saveCardData: (state, action) => {
            state.cardData = action.payload;
            sessionStorage.setItem("cardData", JSON.stringify(action.payload));
        },
        saveCurrentStep: (state, action) => {
            state.currebtStep = action.payload;
            sessionStorage.setItem("currebtStep", JSON.stringify(action.payload));
        },
        saveCardIdAndDesignId: (state, action) => {
            state.cardId = action.payload.cardId;
            state.designId = action.payload.designId;
            sessionStorage.setItem("cardId", JSON.stringify(action.payload.cardId));
            sessionStorage.setItem("designId", JSON.stringify(action.payload.designId));
        },
        clearIdAndDesignId: (state) => {
            state.cardId = null;
            state.designId = null;
            sessionStorage.removeItem("cardId");
            sessionStorage.removeItem("designId");
        }
    }
})

export const homeReducer = home.reducer;
export const { saveCardData, saveCurrentStep, saveCardIdAndDesignId, clearIdAndDesignId } = home.actions;