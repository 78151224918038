import { configureStore } from "@reduxjs/toolkit";
import { auth, authReducer } from "./slices/auth";
import { homeReducer, home } from "./slices/home";
import { usersReducer } from "./slices/users";
import { main, mainReducer } from "./slices/main";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    home: homeReducer,
    users: usersReducer,
    main: mainReducer
  },
});

export const authActions = auth.actions;
export const homeActions = home.actions;
export const mainActions = main.actions
