import { useLocation } from "react-router-dom"
import classes from "./Header.module.scss"
import LanguageDropdown from "./LanguageDropdown"
import NotificationDropDown from "./NotificationDropDown"
import UserDropDown from "./UserDropDown"
import words from "../../assets/words"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { HiOutlineMenu } from "react-icons/hi"
import { useQuery } from "@apollo/client"
import { READ_USER_BY_ID } from "../../graphql/auth/queries"

const Header = ({setShowSideBar}) => {
    const location = useLocation().pathname;
    const { language, token } = useSelector(state => state.auth);
    const [pageTitle, setPageTitle] = useState("")
    const [userData, setUserData] = useState(null);

    // const { refetch: readUser, data: userInfo } = useQuery(READ_USER_BY_ID, {
    //     fetchPolicy: 'cache-and-network',
    //     onCompleted: (data) => {
    //       // debugger
    //       setUserData(data?.myCorporate)
    //     },
    //     onError: (error) => {
    //         console.log(error.message)
    //     }
    //   })



    useEffect(() => {
        switch (location) {
            case "/":
                setPageTitle(words.DASHBOARD[language])
                break;
            case "/promotions":
                setPageTitle(words.PROMOTIONS[language])
                break;
                case "/tickets":
                    setPageTitle(words.TICKETS[language])
                    break;
            default:
                setPageTitle("")
                break;
        }

        location.includes("/client-details") && setPageTitle(words.DASHBOARD[language])
        location.includes("/promo-code") && setPageTitle(words.PROMOTIONS[language])

    }, [location])

    useEffect(()=>{
        // readUser()
    },[token])
    
    // useEffect(()=>{
    //     userInfo && setUserData(userInfo?.myCorporate)
    // },[userInfo])

    return (
        <div className={classes.header}>
         
            <div className={classes.pageTitle}>
            <button onClick={() => {setShowSideBar(true)}}>
                <HiOutlineMenu/>
            </button>
                {pageTitle}
            </div>
            <div className={classes.info}>
                <LanguageDropdown />
                <UserDropDown userData={userData}/>
            </div>
        </div>
    )
}

export default Header