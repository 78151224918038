import logo from '../../assets/images/black-logo.png'
import classes from './SideMenu.module.scss'
import { FiUsers } from 'react-icons/fi'
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import words from '../../assets/words'
import { useLocation, useNavigate } from 'react-router-dom'
import { clearUsersIds } from '../../store/slices/users'
import { clearClientsFilter, clearPromotionsFilter, clearTicketsFilter } from '../../store/slices/main'
import {BsTicketPerforated} from 'react-icons/bs'
import {  TbDiscount2 } from "react-icons/tb";

const SideMenu = ({setShowSideBar}) => {
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.auth);
    const location = useLocation().pathname;
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(location);

    const tabs = [
        {
            icon: <FiUsers />,
            text: words.OUR_CLIENTS[language],
            href: '/'
        },
        {
            icon: <TbDiscount2 />,
            text: words.PROMOTIONS[language],
            href: "/promotions"
        },
        {
            icon: <BsTicketPerforated/>,
            text: words.TICKETS[language],
            href: "/tickets"
        },
    ]

    useEffect(() => {
        setActiveTab(location)
    }, [location, activeTab])


    return (
        <div className={classes.sideMenu}>
            <img src={logo} alt='logo pic' />
            <small>{words.MENU[language]}</small>
            <div className={classes.content}>
                {
                    tabs.map((tab, index) => {
                        return (
                            <div key={index} className={`${activeTab === tab.href ? classes.active : classes.unActive}`} onClick={() => {
                                setActiveTab(index)
                                navigate(tab.href)
                                dispatch(clearUsersIds())
                                dispatch(clearClientsFilter())
                                dispatch(clearPromotionsFilter())
                                dispatch(clearTicketsFilter())
                                setShowSideBar && setShowSideBar(false)
                            }}>
                                {tab.icon}
                                <p>{tab.text}</p>
                            </div>
                        )
                    })
                }
            </div>
           
        </div>
    )
}
export default SideMenu