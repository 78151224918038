import "./App.css";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { useSelector } from "react-redux";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";

const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

const getCountryLinks = () => {
  // debugger
  let links = {
    database:
      "https://swipy-api-production-2a236470d731.herokuapp.com/graphql",
    monolith: "//swipy-api-production-2a236470d731.herokuapp.com/graphql",
  };
  return links;
};

const wsLink = (language, link) =>
  new GraphQLWsLink(
    createClient({
      url: protocol + link.monolith,
      options: {
        reconnect: true,
      },
      connectionParams: {
        Authorization: sessionStorage.getItem("token"),
        language: language,
      },
    })
  );

const httpLink = (link) =>
  createUploadLink({
    uri: link.database,
    // uri: 'https://current--ayamedica-supergraph-r8r9a5.apollographos.net/graphql',
    // uri: 'http://192.168.1.20:4003/graphql',
  });

const splitLink = (language, token) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink(language, getCountryLinks(token)),
    httpLink(getCountryLinks(token))
  );

const authLink = (language, token) =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: (token && token !== "null") ? token : "",
        language: language,
        "keep-alive": "true",
        "content-type": "application/json",
      }
    };
  });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    // debugger
    graphQLErrors.forEach(({ message, locations, path }) => {
      // debugger
      if (message === "Please login to continue") {
        sessionStorage.clear();
        window.location.reload();
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const createApolloClient = (language, token) => {
  return new ApolloClient({
    link: from([
      errorLink,
      authLink(language, token).concat(splitLink(language, token)),
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: (o) => (o.id ? `${o.__typename}-${o.id}` : null),
    }),
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
        returnPartialData: true,
      },
      query: {
        errorPolicy: "all",
        returnPartialData: true,
      },
    },
  });
};

function App() {
  const { language, token } = useSelector(state => state.auth)

  const client = createApolloClient(
    language,
    token
  );

  useEffect(() => {
    sessionStorage.getItem("language")
      ? sessionStorage.getItem("language")
      : sessionStorage.setItem("language", "en");
  }, []);

  return <ApolloProvider client={client}>
    <div>
      <RouterProvider router={router} />
    </div>
  </ApolloProvider>
}

export default App;
