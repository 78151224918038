import { Box, Divider, IconButton, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import classes from "../layout/AuthLayout.module.scss";
import { useEffect, useState } from "react";
import Menu from '@mui/material/Menu';
import words from "../../assets/words";
import {HiOutlineLogout} from 'react-icons/hi'
import {FaUser} from 'react-icons/fa'
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGOUT } from "../../graphql/auth/mutations";
import { authActions } from "../../store";
import { saveToken } from "../../store/slices/auth";
import { uppercaseFirstLetter } from "../../utils/formatWords";
import { clearUsersIds } from "../../store/slices/users";

const UserDropDown = ({userData}) => {
  const navigate = useNavigate();
  const {language, user, token} = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);

  const dispatch = useDispatch()
  const handleClick = (event) => {
      setAnchorEl(event?.currentTarget)
      setOpenEvent(!openEvent)
  };

  useEffect(()=>{
    dispatch(saveToken(token))
  },[token])


  
  const [logout] = useMutation(LOGOUT,{
    onCompleted:(data)=>{
      if(data.adminLogout.success){
        dispatch(authActions.logout());
        navigate("/auth")
        sessionStorage.removeItem("token")
        sessionStorage.clear()
      }
    }
  }
)

  const logoutHandler = () => {
    setOpenEvent(false);
    logout()

  };

  const goToProfile = () => {
    setOpenEvent(false);
    navigate("/profile");
    dispatch(clearUsersIds())
  }

  return (
    <div className={classes.profileInfoContainer}>
      <IconButton
          aria-label="menu"
          color="inherit"
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{borderRadius: "5px",gap:'10px',}}  
        >
          <Box style={{
            gap:'10px',
          }} display="flex" alignItems="center">
            {
              userData?.profilePictureUrl &&
              <img src={userData?.profilePictureUrl} alt="user-pic" style={{width: "40px", height: "40px", borderRadius: "50%", objectFit: "contain"}}/>
            }
          
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                },
                
                alignItems: "center",
                justifyContent: "center",
              }}
            >
                <span
                  className={classes.name}
                  style={{
                    whiteSpace: "nowrap",
                    fontSize:"16px",
                    fontWeight: "bold"
                  }}
                >
                  { user?.fullName && uppercaseFirstLetter( user?.fullName)}
                </span>
            </Box>
          </Box>
        </IconButton>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={openEvent}
          onClose={handleClick}
          sx={{
            "& .MuiMenu-paper": {
              width: "220px",
            },
            "& .MuiList-padding": {
              p: "15px",
            },
          }}
        >
    
          <MenuItem
            sx={{
              pt: 1,
              pb: 1,
              pl: 1,
              pr: 1,
            }}
            onClick={logoutHandler}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={'10px'}
            >
              <HiOutlineLogout size={20} /> {words.LOG_OUT[language]}
            </Box>
          </MenuItem>
        </Menu>
    </div>

  );
};

export default UserDropDown;
