export const selectInput = (array, key, value) => {
  let newArray = [];
  array?.map((item) => {
    const x = {
      value: item[key],
      label: item[value],
    };
    return newArray.push(x);
  });

  return newArray;
};
export const capitalizeAllWords=(str)=> {
  return str?.toUpperCase()
}
export const fontList = [
  { value: "Roboto_Regular", label: "Roboto" },
  { value: "Poppins_Regular", label: "Poppins" },
  { value: "Inter_Regular", label: "Inter" },
  { value: "Pacifico_Regular", label: "Pacifico" },
  { value: "SegoeUI", label: "Segoe UI" },
  { value: "SansSerif", label: "sans serif" },
  { value: "DMSa_Regular", label: "DM Sans" },
  { value: "CherryBombOne_Regular", label: "Cherry Bomb One" },
  { value: "Buenard_Regular", label: "Buenard" },
  { value: "PlayfairDisplay_Regular", label: "Playfair Display" },
  { value: "TimesNewRomanPSMT", label: "Times New Roman" },
  { value: "Helvetica", label: "Helvetica" },
  { value: "Baskervville_Regular", label: "Baskerville" },
  { value: "Futura_Medium", label: "Futura" },
  { value: "MyriadPro_Regular", label: "Myriad Pro" },
  { value: "BlackCaviarALT_Regular", label: "Black Caviar" },
  { value: "ArialMT", label: "Arial" },
  { value: "Riviera_Regular", label: "Riviera" },
  { value: "TrajanPro_Regular", label: "Trajan Pro" },
  { value: "LeytonPersonal_Bold", label: "Leyton" },
  { value: "MoskNormal400", label: "Mosk" },
  { value: "AppleGaramond", label: "Apple Garamond" },
  { value: "Kiona_Regular", label: "Kiona" }
]

export const isDarkColor = (color) => {
  const rgb = color.match(/\d+/g);
  if (!rgb) return false;
  const [r, g, b] = rgb.map(Number);
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  const threshold = 128;
  return luminance < threshold;
}

export const selectValueFormat = (value) => {
  if (!value) return null;
  return { value: value, label: value };
};

export const formatAId = (aId) => {
  return aId.match(/.{1,3}/g).join("-");
};

export const uppercaseFirstLetter = (input) => {
  const formatedInput = input?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  return formatedInput;
}

export const formatPhoneNumber = (number) => {
  let formatedNumber = number;
  if (formatedNumber.startsWith("0")) {
    formatedNumber = number.replace("0", "");
    return formatedNumber
  }
  else {
    return formatedNumber
  }
};

export const phoneNumberIsValid = ({ countryCode, number }) => {
  let regex = new RegExp(/^[+]{1}(?:[0-9\-\(\)\/\.]\s?){9,15}[0-9]{1}$/);
  return regex.test(countryCode + number);
}

export const cutWords = (word, num) => {
  let cutNumber = num || 20;
  if (word && word.length > cutNumber) {
    return word.slice(0, cutNumber) + "...";
  } else {
    return word;
  }
};

export const emailIsInvalid = (email) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email !== null && email.match(emailRegex) === null) {
    return true;
  } else {
    return false;
  }
};

export const formatDate = (inputDate) => {
  if (!inputDate) return "";
  const date = new Date(inputDate);
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedDate = dd + "/" + mm + "/" + yyyy;
  return formattedDate;
};

export const longDateformat = (inputDate) => {
  const date = new Date(inputDate);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return day + " " + monthNames[monthIndex] + " " + year;
};
export const renderTodayOrYesterday = (date) => {
  const currentDate = new Date();
  const givenDate = new Date(date);
  const isToday =
    givenDate.getDate() === currentDate.getDate() &&
    givenDate.getMonth() === currentDate.getMonth() &&
    givenDate.getFullYear() === currentDate.getFullYear();

  const isYesterday =
    givenDate.getDate() === currentDate.getDate() - 1 &&
    givenDate.getMonth() === currentDate.getMonth() &&
    givenDate.getFullYear() === currentDate.getFullYear();

  if (isToday) {
    return <span> Today, {givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} </span>;
  } else if (isYesterday) {
    return <span> Yesterday, {givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>;
  } else {
    return <span>{givenDate.toDateString()} {","} {givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
    </span>;
  }
}

export const formatDateToAPI = (inputDate) => {
  if (!inputDate) return "";
  let [d, m, y] = inputDate.split("/");
  if (+d < 10) d = "0" + +d;
  if (+m < 10) m = "0" + +m;
  return `${y}-${m}-${d}`;
};
export const getNextDate = (date) => {
  let d = new Date(formatDateToAPI(date));
  d.setDate(d.getDate() + 1);

  let year = d.getFullYear()
  let month = String(d.getMonth() + 1)
  let day = String(d.getDate())

  month = month.length == 1 ?
      month.padStart('2', '0') : month;

  day = day.length == 1 ?
      day.padStart('2', '0') : day;

  return `${year}-${month}-${day}`
}
export const formatDateFromAPI = (inputDate) => {
  if (!inputDate) return "";
  let [y, m, d] = inputDate.split("-");
  if (+d < 10) d = "0" + +d;
  if (+m < 10) m = "0" + +m;
  return `${d}/${m}/${y}`;
};

export const valueNull = (value) => {
  if (!value && value !== 0) return "-";
  else return value;
};

export const formatTime = (inputDate) => {
  const time = new Date(inputDate).toLocaleTimeString("en", {
    timeStyle: "short",
    hour12: true,
    timeZone: "UTC",
  });
  return time;
};

export const shortenString = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.substring(0, maxLength - 3) + "...";
};

export const shortenDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const time = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return time;
};
export const truncateUrl = (url) => {
  const maxLength = 20;
  if (url.length <= maxLength) {
    return url;
  }
  const truncatedUrl = url.slice(0, maxLength) + "...";
  return truncatedUrl;
};


export const formatDateAndTime = (inputDate) => {
  var newDate = new Date(inputDate);

  var sMonth = padValue(newDate.getMonth() + 1);
  var sDay = padValue(newDate.getDate());
  var sYear = newDate.getFullYear();
  var sHour = newDate.getHours();
  var sMinute = padValue(newDate.getMinutes());
  var sAMPM = "AM";

  var iHourCheck = parseInt(sHour);

  if (iHourCheck > 12) {
    sAMPM = "PM";
    sHour = iHourCheck - 12;
  }
  else if (iHourCheck === 0) {
    sHour = "12";
  }

  sHour = padValue(sHour);

  return sDay + "/" + sMonth + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
}

function padValue(value) {
  return (value < 10) ? "0" + value : value;
}

export const formatAMPM = (inputDate) => {
  const date = new Date(inputDate);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  const time = date.toLocaleString([], {
    hour: '2-digit',
    minute: '2-digit'
  });
  return time
}

export const compareDates = (date, timeSlot) => {
  const dateParts = date.split("/");
  let mainDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  let todaysDate = new Date();
  if (mainDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
    let inputDate = new Date(timeSlot)
    inputDate.setMinutes(inputDate.getMinutes() + inputDate.getTimezoneOffset())
    const inputTime = inputDate.toLocaleString([], {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    });
    const currentTime = new Date().toLocaleString([], {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    });

    if (inputTime <= currentTime) {
      return true
    }
    else {
      return false
    }
  }
  else {
    return false
  }
}

//[{label: 1, value: 1} ]==> [1]
export const convertSelectIntoArr = (arr) => {
  return arr?.map((obj) => {
    return obj?.value;
  });
};

//[1] ==> [{label: 1, value: 1}]
export const convertArrIntoSelect = (arr) => {
  return arr?.map((obj) => {
    return { label: obj, value: obj };
  });
};

export const isValidEmail = (email) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
};

export const formatDateToDatePicker = (inputDate) => {
  const dateSplit = inputDate.split("/");
  return {
    year: dateSplit[2],
    month: dateSplit[1],
    day: dateSplit[0]
  }
}