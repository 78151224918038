import { gql } from "@apollo/client";

export const READ_USER_BY_ID = gql `
query MyCorporate {
  myCorporate {
    _id
    fullName
    email
    isEmailVerified
    phoneNumber
    countryCode
    isCompletedInformation
    position
    corporateName
    corporateSize
    industry
    profilePictureUrl
    webFirebaseToken
  }
}
`