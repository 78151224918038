const words = {
  ERROR: {
    ar: "تم حدوث خطأ!",
    en: "An Error Occurred!",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  RESET:{
    ar:'ارجاع',
    en:'Reset',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  FILTER_BY_DATE:{
    ar:'ارجاع',
    en:'Filter by Date',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  SAVE : {
    ar:'حفظ',
    en:'Save',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  UPLOAD_ERROR : {
    ar:'حفظ',
    en:'Couldn\'t Upload Excel sheet please try again',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  COMPANY_NAME : {
    ar:'اسم الشركة',
    en:'Company Name',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  AFFILIATE : {
    ar:'ترويجي',
    en:'Affiliate',
    tu: "Giriş ",
    ru: "Войти",
    fr: "S'identifier",
  },
  MAXIMUM_VALUE : {
    ar:'الحد الاقصي',
    en:'Maximum Value Is',
    tu: "Giriş ",
    ru: "Войти",
    fr: "S'identifier",
  },
  MINMUM_VALUE : {
    ar:'يجب ان يكون اكبر من الصفر',
    en:'Must be greater than 0',
    tu: "Giriş ",
    ru: "Войти",
    fr: "S'identifier",
  },
  CHOOSE_TYPE : {
    ar:'اختر نوع العرض الترويجي',
    en:'Choose Type of promo',
    tu: "Giriş ",
    ru: "Войти",
    fr: "S'identifier",
  },

  LANGUAGE : {
    ar:'اللغة',
    en:'Language',
    tu: "",
    ru: "",
    fr: "",
  },
  LOADING : {
    ar:'حفظ',
    en:'Loading',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  NO_MORE_OPTIONS : {
    ar:'حفظ',
    en:'No more options',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CHANGE_YOUR_EMAIL:{
    ar:'تغيير البريد الألكتروني',
    en:'Change your E-mail',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier", 
  },
  CHANGE_YOUR_PASSWORD:{
    ar:'تغيير كلمه المرور ',
    en:'Change your password',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier", 
  },
  YOUR_PASS_CHANGED:{
    ar:'تم تغيير كلمة السر بنجاح',
    en:'Your Password Changed Successfully',
    tu: "",
    ru: "",
    fr: "", 
  },
  NEXT : {
    ar:'التالي',
    en:'Next',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  WEBSITE : {
    ar:'الموقع',
    en:'Website',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  DONE : {
    ar:'تم',
    en:'Done',
    tu: "",
    ru: "",
    fr: "",
  },
  CARD : {
    ar:'بطاقة',
    en:'Card',
    tu: "",
    ru: "",
    fr: "",
  },
  COLORS : {
    ar:'الالوان',
    en:'Colors',
    tu: "",
    ru: "",
    fr: "",
  },
  COLOR : {
    ar:'لون',
    en:'Color',
    tu: "",
    ru: "",
    fr: "",
  },
  QR: {
    en: "QR",
    ar: "QR",
    tu: "",
    ru: "",
    fr: "",
  },
  FONT : {
    ar:'الخط',
    en: 'Font',
    tu: "",
    ru: "",
    fr: "",
  },
  TEAM : {
    ar:'اعضاء',
    en: 'Team',
    tu: "",
    ru: "",
    fr: "",
  },
  UPGRADE_NOW : {
    ar:'',
    en:'Upgrade Now',
    tu: "",
    ru: "",
    fr: "",
  },
  YOUR_CARD : {
    ar:'',
    en:'Your Card',
    tu: "",
    ru: "",
    fr: "",
  },
  
  USER_IS_NOT_SUBSCRIBED: {
    ar: "هذا المستخدم ليس مشكترك",
    en: "This user is not subscribed",
    tu: "",
    ru: "",
    fr: "",
  },
  ERROR_MESSAGE: {
    ar: "برجاء التواصل بالدعم",
    en: "Please Contact Support",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ENTER_VERIFICATION_CODE:{
    ar: "برجاء ادخال كود التفعيل",
    en: "Please enter the verification code  we just sent",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  VERIFICATION_CODE:{
    ar: "برجاء ادخال كود التفعيل",
    en: "Verification code",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  DURATION_IN_MONTHS:{
    ar: "عدد الشهور",
    en: "Duration in months",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  SWIPY_DESC: {
    ar: " اكتشف نهجًا جديدًا ومبتكرًا للتفاعلات الرقمية، وتبسيط مشاركة المعلومات وتعزيز الاتصالات الهادفة، كل ذلك مع إعطاء الأولوية لرفاهية كوكبنا. ",
    en: "Discover a fresh and innovative approach to digital interactions, simplifying information sharing and fostering meaningful connections, all while prioritizing the well-being of our planet.",
    tu: "",
    ru: "",
    fr: "",
  },
  MAKE_SURE_YOU_ENTERED_CORRECT_EMAIL:{
    ar: "برجاء ادخال كود التفعيل",
    en: "Please Make sure you entered correct email",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  TO_YOUR_EMAIL:{
    ar: "برجاء ادخال كود التفعيل",
    en: "To your email",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  YOUR_INFO:{
    ar: "بياناتك",
    en: "Your Info",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ORGANIZATION_INFO:{
    ar: "",
    en: "Organization Info",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
 PLEASE_MAKE_SURE:{
  ar: "تأكد منادخالك للبريد الألكتروني الصحيح",
    en: "Please Make Sure you entered Correct Email",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  FULL_NAME:{
    ar: "الأسم بالكامل",
    en: "Full-Name",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  EMAIL:{
    ar: "البريد الألكتروني",
    en: "Email",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  WELCOME_TO: {
    ar: "مرحبا بك في",
    en: "Welcome to",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PASSWORD:{
    ar: "كلمه المرور",
    en: "password",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CURRENT_PASSWORD:{
    ar: "كلمه المرور",
    en: "Current password",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  NEW_PASSWORD:{
    ar: "كلمه المرور",
    en: "New password",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  LOG_IN: {
    ar: "تسجيل الدخول",
    en: "Login",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  SIGN_UP: {
    en: "Signup",
    ar: "تسجيل",
    tu: "Kayıt Ol",
    ru: "Зарегистрироваться",
    fr: "S'inscrire",
  },
  DO_NOT_HAVE_AN_ACCOUNT:{
    ar: "ليس لديك حساب",
    en: "don't have an account?",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  EDIT_YOUR_INFO :{
    ar: "تعديل بيانات",
    en: "Edit Your Info ",
    tu: "Şifremi Unuttum ?",
    ru: "Забыли пароль ?",
    fr: "Mot de passe oublié ?",
  },
  FORGET_PASSWORD: {
    ar: "نسيت كلمة المرور ؟",
    en: "Forgot Password ?",
    tu: "Şifremi Unuttum ?",
    ru: "Забыли пароль ?",
    fr: "Mot de passe oublié ?",
  },
  MINIMUM_8_CHARACTERS: {
    ar: "الحد الأدنى 8 أحرف",
    en: "Minimum 8 characters",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  AT_LEAST_1_LOWERCASE: {
    ar: "حرف صغير واحد على الأقل (a-z)",
    en: "Has At Least 1 Lowercase Character (a-z)",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  AT_LEAST_1_UPPERCASE: {
    ar: "حرف كبير واحد على الأقل (a-z)",
    en: "Has At Least 1 Uppercase Character (a-z)",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  AT_LEAST_1_NUMBER: {
    ar: "رقم واحد على الأقل (0-9)",
    en: "Has At Least 1 Number (0-9)",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  PASSWORDS_DO_NOT_MATCH: {
    ar: "كلمات المرور غير متطابقة",
    en: "The passwords do not match",
    tu: "Devam et",
    ru: "Продолжить",
    fr: "Continuer",
  },
  CONFIRM_PASSWORD: {
    en: "Confirm Password",
    ar: "تأكيد كلمة المرور",
    tu: "Şifreyi Onayla",
    ru: "Подтвердите Пароль",
    fr: "Confirmer le mot de passe",
  },
  ORGANIZATION_NAME:{
    ar: "كلمه المرور",
    en: "Organization Name",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  INDUSTRY:{
    ar: "كلمه المرور",
    en: "Industry",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  JOB_TITLE:{
    ar: "الوظيفه",
    en: "Job title",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  TITLE: {
    ar: "الوظيفه",
    en: "Title",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  JOINED: {
    ar: "انضم",
    en: "Joined",
    tu: "",
    ru: "",
    fr: "",
  },
  SWIPY: {
    ar: "Swipy",
    en: "Swipy",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },

  CARD_DESIGN: {
    ar: "",
    en: "Card Design",
    tu: "",
    ru: "",
    fr: "",
  },
  SIGN_IN: {
    ar: "",
    en: "Sign In",
    tu: "",
    ru: "",
    fr: "",
  },
  USERS: {
    ar: "مستخدمون",
    en: "Users",
    tu: "",
    ru: "",
    fr: "",
  },
  TICKETS: {
    ar: "مستخدمون",
    en: "Tickets",
    tu: "",
    ru: "",
    fr: "",
  },
  DASHBOARD: {
    ar: "",
    en: "Dashboard",
    tu: "",
    ru: "",
    fr: "",
  },
  USER_NAME: {
    ar: "",
    en: "User Name",
    tu: "",
    ru: "",
    fr: "",
  },
  SUCCESS: {
    ar: "",
    en: "Success",
    tu: "",
    ru: "",
    fr: "",
  },
  PROFILE : {
    ar:'الصفحه الشخصيه',
    en:'Profile',
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  HAVE_ACCOUNT: {
    ar: "",
    en: "Do you have an account? ",
    tu: "",
    ru: "",
    fr: "",
  },
  BILLING: {
    ar: "",
    en: "Billing",
    tu: "",
    ru: "",
    fr: "",
  },
  PLANS_DESC: {
    ar: "",
    en: "We have powerful plans to Showcase your business and get discovered as a creative entrepreneurs. Every thing you need.",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_TYPE: {
    ar: "",
    en: "Standard",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_TYPE_STANDARD: {
    ar: "",
    en: "Standard",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_USERS: {
    ar: "",
    en: "per user",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS1: {
    ar: "",
    en: "Everything in Solo",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS2: {
    ar: "",
    en: "Bulk Creation",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS3: {
    ar: "",
    en: "Template Management",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS4: {
    ar: "",
    en: "Everything in Team",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS5: {
    ar: "",
    en: "Integrations (e.g. Active Directory)",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_PROS6: {
    ar: "",
    en: "Template Enforcement",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_SUBSCRIBE: {
    ar: "انضمام",
    en: "Subscribe",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_FREE_TRIAL: {
    ar: "شهر محاني",
    en: "1 Month Free Trial",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_ONE_STARTED_FREE_TRIAL: {
    ar: "تواصل معنا",
    en: "You have started 1 month free trail",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_TYPE_PLUS: {
    ar: "مميز",
    en: "PLUS",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_TWO_USERS: {
    ar: "تحدث مع مسئولي المبيعات",
    en: "Talk to Sales",
    tu: "",
    ru: "",
    fr: "",
  },
  PLAN_TWO_CONTACT_US: {
    ar: "تواصل معنا",
    en: "Contact Us",
    tu: "",
    ru: "",
    fr: "",
  },
  ORGANIZATION: {
    ar: "",
    en: "Organization Name",
    tu: "",
    ru: "",
    fr: "",
  },
  ORGANIZATION_SIZE: {
    ar: "",
    en: "Company Size",
    tu: "",
    ru: "",
    fr: "",
  },
  
  ORGANIZATION_INDUSTRY: {
    ar: "",
    en: "Industry",
    tu: "",
    ru: "",
    fr: "",
  },
  PRIMARY_COLOR: {
    ar: "",
    en: "Primary Color",
    tu: "",
    ru: "",
    fr: "",
  },
  SECONDARY_COLOR: {
    ar: "",
    en: "Secondary Color",
    tu: "",
    ru: "",
    fr: "",
  },
  QR_COLOR: {
    ar: "",
    en: "Qr Color",
    tu: "",
    ru: "",
    fr: "",
  },
  QR_CODE_LAYOUT: {
    ar: "",
    en: "Qr Code Layout",
    tu: "",
    ru: "",
    fr: "",
  },
  FONT_COLOR: {
    ar: "",
    en: "Font Color",
    tu: "",
    ru: "",
    fr: "",
  },

  AUTOMATICALLY_USE_YOUR_LOGO: {
    ar: "",
    en: "Automatically use your logo",
    tu: "",
    ru: "",
    fr: "",
  },
  AUTOMATICALLY_USE_YOUR_FONT_COLOR: {
    ar: "",
    en: "Automatically use your font color",
    tu: "",
    ru: "",
    fr: "",
  },
  

 
  MENU: {
    ar: "",
    en: "Menu",
    tu: "",
    ru: "",
    fr: "",
  },
  LOG_OUT: {
    ar: "تسجيل الخروج",
    en: "Log Out",
    tu: "Çıkış Yap",
    ru: "Выйти",
    fr: "Se déconnecter",
  },
  MY_PROFILE: {
    en: "My Profile",
    ar: "ملفي الشخصي",
    tu: "Profilim",
    ru: "Мой профиль",
    fr: "Mon profil",
  },
  CARDS:{
    en: "Cards",
    ar: "بطاقات",
    tu: "Kartlar",
    ru: "Карты",
    fr: "Cartes",
  },
  MY_CARD: {
    en: "My Card",
    ar: "بطاقتي",
    tu: "Kartım",
    ru: "Моя карта",
    fr: "Ma carte",
  },
  NO_USERS_FOUND: {
    en: "You don’t have contacts, try to make them share their business cards with you",
    ar: "ليس لديك اتصالات ، حاول جعلهم يشاركون بطاقات عملهم معك",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_CARDS_FOUND: {
    en: "You don’t have business card add one",
    ar: "ليس لديك بطاقة عمل أضف واحدة",
    tu: "",
    ru: "",
    fr: "",
  },
  UPLOAD_EXCEL_SHEET: {
    en: "Upload Excel Sheet",
    ar: "تحميل ورقة Excel",
    tu: "",
    ru: "",
    fr: "",
  },
  DOWNLOAD_TEMPLETE: {
    en: "Download Templete",
    ar: "تحميل القالب",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_USERS: {
    en: "No Users",
    ar: "لا يوجد مستخدمين",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_PRMOTIONS: {
    en: "No Promotions",
    ar: "لا يوجد ترقيات",
    tu: "",
    ru: "",
    fr: "",
  },
  NO_TICKETS: {
    en: "No Tickets",
    ar: "لا يوجد ترقيات",
    tu: "",
    ru: "",
    fr: "",
  },
  TOTAL_PROMOTIONS: {
    en: "Total Promotions",
    ar: "مجموع الترقيات",
    tu: "",
    ru: "",
    fr: "",
  },
  LAST_EDIT: {
    en: "Last Edit",
    ar: "اخر تعديل",
    tu: "",
    ru: "",
    fr: "",
  },
  NAME: {
    en: "Name",
    ar: "الاسم",
    tu: "",
    ru: "",
    fr: "",
  },
  PROMOTIONS_NAME: {
    en: "Promotions Name",
    ar: "اسم الترقيات",
    tu: "",
    ru: "",
    fr: "",
  },
  CUSTOMERS_NAME: {
    en: "Customer",
    ar: "اسم الترقيات",
    tu: "",
    ru: "",
    fr: "",
  },
  CUSTOMER: {
    en: "Customer", 
    ar: "العميل",
    tu: "",
    ru: "",
    fr: "",
  },
  PACKAGE_NAME: {
    en: "Package Name",
    ar: "اسم الحزمة",
    tu: "",
    ru: "",
    fr: "",
  },
  PACKAGE_ACTIVITY: {
    en: "Package Activity",
    ar: "تاريخ الحزمة",
    tu: "",
    ru: "",
    fr: "",
  },

  NO_USAGES: {
    en: "No Usages",
    ar: "لا يوجد استخدامات",
    tu: "",
    ru: "",
    fr: "",
  },
  DISCOUNT: {
    en: "Discount",
    ar: "خصم",
    tu: "",
    ru: "",
    fr: "",
  },
  PHONE_NUMBER: {
    en: "Phone Number",
    ar: "رقم الهاتف",
    tu: "",
    ru: "",
    fr: "",
  },
  ACTIONS: {
    en: "Actions",
    ar: "الإجراءات",
    tu: "",
    ru: "",
    fr: "",
  },
  MOBILE: {
    en: "Mobile",
    ar: "هاتف",
    tu: "",
    ru: "",
    fr: "",
  },
  CORPORATE: {
    en: "Corporate",
    ar: "شركة",
    tu: "",
    ru: "",
    fr: "",
  },
  NEXT_PAYMENT_DATE: {
    en: "Next Payment Date",
    ar: "موعد الدفع التالي",
    tu: "",
    ru: "",
    fr: "",
  },
  CURRENT_PAYMENT_DATE_END: {
    en: "Current Period End Date",
    ar: "انتهاء المدة الحالية",
    tu: "",
    ru: "",
    fr: "",
  },
  CURRENT_PAYMENT_DATE_START: {
    en: "Current Period Start Date",
    ar: "بداية المدة الحالية",
    tu: "",
    ru: "",
    fr: "",
  },
  FREE_TRIAL_END: {
    en: "Free Trial End Date",
    ar: "تاريخ انتهاء الفترة المجانية",
    tu: "",
    ru: "",
    fr: "",
  },
  FREE_TRIAL_START: {
    en: "Free Trial Start Date",
    ar: "موعد بداية الفترة المجانية",
    tu: "",
    ru: "",
    fr: "",
  },

  DISPLAYED: {
    en: "Displayed",
    ar: "معروض",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  OUT_OF: {
    en: "Out Of",
    ar: "من",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant",
  },
  PREVIOUS: {
    en: "Previous",
    ar: "السابق",
    tu: "Sonraki",
    ru: ""
  },
  ADD_USER: {
    en: "Add User",
    ar: "إضافة مستخدم",
    tu: "Sonraki",
    ru: "Далее",
    fr: "Suivant"
  },
  ADD_CARD: {
    en: "Add Card",
    ar: "إضافة بطاقة",
    tu: "",
    ru: "",
    fr: ""
  },
  ADD_NEW_PROMOTION: {
    en: "Add New Promotion",
    ar: "إضافة ترقية جديدة",
    tu: "",
    ru: "",
    fr: ""
  },
  YOU_DONT_HAVE_ANY_CARD: {
    en: "You don’t have any cards please make it first the upload users",
    ar: "ليس لديك أي بطاقات، يرجى جعلها أولًا للمستخدمين الذين يقومون بالتحميل",
    tu: "",
    ru: "",
    fr: ""
  },
  YOU_DONT_HAVE_ANY_CARD_TO_ADD_USER: {
    en: "You don’t have any cards to add user",
    ar: "ليس لديك أي بطاقات لإضافة مستخدم",
    tu: "",
    ru: "",
    fr: ""
  },
  BROWSE: {
    en: "Browse",
    ar: "تصفح",
    tu: "",
    ru: "",
    fr: ""
  },
  DRAG_AND_DROP: {
    en: "Drag and drop files to upload",
    ar: "قم بسحب وإسقاط الملفات للتحميل",
    tu: "",
    ru: "",
    fr: ""
  },
  OR: {
    en: "or",
    an: "او",
    tu: "",
    ru: "",
    fr: ""
  },
  UPLOAD_FILE: {
    en: "Upload File",
    ar: "تحميل الملف",
    tu: "",
    ru: "",
    fr: ""
  },
  ALL: {
    en: "All",
    ar: "الكل",
    tu: "",
    ru: "",
    fr: ""
  },
  CORRECT: {
    en: "Correct",
    ar: "صحيح",
    tu: "",
    ru: "",
    fr: ""
  },
  EXCEL_ERROR_MESSAGE: {
    en: "There is errors In your Excel sheet Submitting Data will remove the errored From your sheet, are you sure You want to continue review and submit?",
    ar: "خطأ",
    tu: "",
    ru: "",
    fr: ""
  },
  DATA_SUBMITTED_MESSAGE: {
    en:"Your Data Has been submitted successfully",
    ar: "خطأ",
    tu: "",
    ru: "",
    fr: ""
  },
  REVIEW_AND_SUBMIT: {
    en: "Review and Submit",
    ar: "مراجعة وإرسال",
    tu: "",
    ru: "",
    fr: ""
  },
  EXPORT_IN_EXCEL: {
    en: "Export in Excel Sheet",
    ar: "تصدير في ورقة Excel",
    tu: "",
    ru: "",
    fr: ""
  },
  POSITION: {
    en: "Position",
    ar: "موضع",
    tu: "",
    ru: "",
    fr: ""
  },
  FACEBOOK: {
    en: "Facebook",
    ar: "فيسبوك",
    tu: "",
    ru: "",
    fr: ""
  },
  LINKED_IN:{
    en: "Linked In",
    ar: "لينكد إن",
    tu: "",
    ru: "",
    fr: ""
  },
  INSTERGRAM: {
    en: "Instagram",
    ar: "انستجرام",
    tu: "",
    ru: "",
    fr: ""
  },
  YOUTUBE: {
    en: "Youtube",
    ar: "يوتيوب",
    tu: "",
    ru: "",
    fr: ""
  },
  FAX: {
    en: "Fax",
    ar: "فاكس",
    tu: "",
    ru: "",
    fr: ""
  },
  TELEPHONE: {
    en: "Telephone",
    ar: "هاتف",
    tu: "",
    ru: "",
    fr: ""
  },
  DELETE_PROMO: {
    en: "Delete PromoCode",
    ar: "حذف المستخدم",
    tu: "",
    ru: "",
    fr: ""
  },
  YES: {
    en: "Yes",
    ar: "نعم",
    tu: "",
    ru: "",
    fr: ""
  },
  NO: {
    en: "No",
    ar: "لا",
    tu: "",
    ru: "",
    fr: ""
  },
  PUSH_NOTIFICATIONS: {
    en: "Push Notification",
    ar: "ارسال اشعارات",
    tu: "",
    ru: "",
    fr: ""
  },
  AFFILIATE_PROMO: {
    en: "Affiliate Promo",
    ar: "خصم ترويجي",
    tu: "",
    ru: "",
    fr: ""
  },
  ON: {
    en: "On",
    ar: "فتح",
    tu: "",
    ru: "",
    fr: ""
  },
  OFF: {
    en: "Off",
    ar: "غلق",
    tu: "",
    ru: "",
    fr: ""
  },

  ARE_YOU_SURE_TO_DELETE: {
    en: "Are you sure you want to delete this PromoCode?",
    ar: "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
    tu: "",
    ru: "",
    fr: ""
  },
  INVOICE_ID: {
    en: "Invoice ID",
    ar: "معرف الفاتورة",
    tu: "",
    ru: "",
    fr: ""
  },
  AMOOUNT: {
    en: "Amount",
    ar: "كمية",
    tu: "",
    ru: "",
    fr: ""
  },
  DATE: {
    en: "Date",
    ar: "التاريخ",
    tu: "",
    ru: "",
    fr: ""
  },
  PAY_NOW: {
    en: "Pay Now",
    ar: "ادفع الان",
    tu: "",
    ru: "",
    fr: ""
  },
  STATUS: {
    en: "Status",
    ar: "الحالة",
    tu: "",
    ru: "",
    fr: ""
  },
  ACTION: {
    en: "Action",
    ar: "عمل",
    tu: "",
    ru: "",
    fr: ""
  },
  NO_INVOICES_FOUND: {
    en: "No invoices found",
    ar: "لم يتم العثور على فواتير",
    tu: "",
    ru: "",
    fr: ""
  },
  SEARCH: {
    en: "Search",
    ar: "ابحث",
    tu: "",
    ru: "",
    fr: ""
  },
  ENTER_FULL_EMAIL: {
    en: "Enter Full Email",
    ar: "ادخل البريد الألكتروني بالكامل",
    tu: "",
    ru: "",
    fr: ""
  },
  FILTER: {
    en: "Filter",
    ar: "تصفية",
    tu: "",
    ru: "",
    fr: ""
  },
  YOUR_PHOTO: {
    en: "Your Photo",
    ar: "صورتك",
    tu: "",
    ru: "",
    fr: ""
  },
  UPLOAD_PHOTO: {
    en: "Upload Photo",
    ar: "تحميل الصور",
    tu: "",
    ru: "",
    fr: ""
  },
  LOGO: {
    en: "Logo",
    ar: "شعار",
    tu: "",
    ru: "",
    fr: ""
  },
  UPLOAD_LOGO: {
    en: "Upload Logo",
    ar: "تحميل الشعار",
    tu: "",
    ru: "",
    fr: ""
  },
  PERSONAL_IFO: {
    en: "Personal Info",
    ar: "معلومات شخصية",
    tu: "",
    ru: "",
    fr: ""
  },
  YOUR_CONTANT: {
    en: "Your Contact",
    ar: "اتصالك",
    tu: "",
    ru: "",
    fr: ""
  },
  PROBLEM_DETAILS: {
    en: "Problem Details",
    ar: "اتصالك",
    tu: "",
    ru: "",
    fr: ""
  },
  LINKS: {
    en: "Links",
    ar: "روابط",
    tu: "",
    ru: "",
    fr: ""
  },
  ASSIGN_TO_USERS: {
    en: "Assign to Users",
    ar: "تعيين للمستخدمين",
    tu: "",
    ru: "",
    fr: ""
  },
  NOTIFICATION: {
    en: "Notification",
    ar: "إعلام",
    tu: "",
    ru: "",
    fr: ""
  },
  RESEND_CODE: {
    en: "Resend Code",
    ar: "إعادة إرسال الرمز",
    tu: "",
    ru: "",
    fr: ""
  },
  STEP_ONE: {
    en: "Step 1",
    ar: "الخطوة 1",
    tu: "",
    ru: "",
    fr: ""
  },
  STEP_TWO: {
    en: "Step 2",
    ar: "الخطوة 2",
    tu: "",
    ru: "",
    fr: ""
  },
  STEP_THREE: {
    en: "Step 3",
    ar: "الخطوة 3",
    tu: "",
    ru: "",
    fr: ""
  },
  LAYOUT: {
    en: "Layout",
    ar: "تخطيط",
    tu: "",
    ru: "",
    fr: ""
  },
  ENTER_DATA: {
    en: "Enter Data",
    ar: "أدخل البيانات",
    tu: "",
    ru: "",
    fr: ""
  },
  LUNACH: {
    en: "Lunach",
    ar: "إطلاق",
    tu: "",
    ru: "",
    fr: ""
  },
  UPDATE: {
    en: "Update",
    ar: "تحديث",
    tu: "",
    ru: "",
    fr: ""
  },
  FREE_TRIAL: {
    en: "Free Trial",
    ar: "تجربة مجانية",
    tu: "",
    ru: "",
    fr: ""
  },
  MONTH: {
    en: "Month",
    ar: "شهر",
    tu: "",
    ru: "",
    fr: ""
  },
  DAYS: {
    en: "Days",
    ar: "أيام",
    tu: "",
    ru: "",
    fr: ""
  },
  DAY: {
    en: "Day",
    ar: "يوم",
    tu: "",
    ru: "",
    fr: ""
  },
  CANNOT_BE_EMPTY: {
    en: "This can not be empty",
    ar: "هذا لا بجب ان يكون فارغ",
    tu: "",
    ru: "",
    fr: ""
  },
  I_REQUEST_CUSTOM_DESIGN: {
    en: "I request custom design",
    ar: "أطلب تصميم مخصص",
    tu: "",
    ru: "",
    fr: ""
  },
  CURRENT_NEW_PASSWORDS_SAME: {
    ar: "كلمة المرور الحالية وكلمة المرور الجديدة لا يمكن أن تكونا نفسهما!",
    en: "Current password and new password cannot be the same!",
    tu: "",
    ru: "",
    fr: ""
  },
  CONNECTION_ERROR: {
    ar: "خطأ في الاتصال!",
    en: "Connection Error!",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  CHECK_YOUR_NETWORK: {
    ar: "يرجى التحقق من اتصالك بالإنترنت والمحاولة مرة أخرى.",
    en: "Please check your internet connection and try again.",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  OUR_CLIENTS: {
    ar: "عملائنا",
    en: "Our Clients",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PROMOTIONS: {
    ar: "الترقيات",
    en: "Promotions",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  TICKETS: {
    ar: "الترقيات",
    en: "Tickets",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PROMO_CODE: {
    ar: "رمز الترويجي",
    en: "Promo Code",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PROMOTION_NAME: {
    ar: "اسم الترقية",
    en: "Promotion Name",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  NUMBER_OF_USAGES: {
    ar: "عدد الاستخدامات",
    en: "Number of Usages",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  TYPE: {   
    ar: "نوع",
    en: "Type",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  START_DATE: {
    ar: "تاريخ البدء",
    en: "Start Date",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  END_DATE: {
    ar: "تاريخ الانتهاء",
    en: "End Date",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  DISCOUNT_TYPE: {
    ar: "نوع الخصم",
    en: "Discount Type",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ENTER_TEXT: {
    ar: "أدخل النص",
    en: "Enter Text",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ENTER_NUMBER: {
    ar: "أدخل الرقم",
    en: "Enter Number",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ENTER_DOLLAR_NUMBER: {
    ar: " أدخل الرقم بالدولار",
    en: "Enter Number in Dollar",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ENTER_CENT_NUMBER: {
    ar: " أدخل الرقم بالسينت",
    en: "Enter Number in Cents",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  SELECT_ONE: {
    ar: "اختر واحد",
    en: "Select One",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  FIXED: {
    ar: "ثابت",
    en: "Fixed",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PERCENTAGE: {
    ar: "النسبة المئوية",
    en: "Percentage",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PLEASE_ENTER_A_NUMBER: {
    ar: "الرجاء إدخال رقم",
    en: "Please enter a number",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  ADD_PROMO: {
    ar: "إضافة ترقية",
    en: "Add Promo",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PROMO_ADDED_SUCCESSFULLY: {
    ar: "تمت إضافة الترويج بنجاح",
    en: "This Promo has been added successfully",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PROMO_CODE_ALREADY_EXISTS: {
    ar: "رمز الترويجي موجود بالفعل",
    en: "Promo Code Is Already Exist",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  THIS_PROMO_CODE_IS_ALREADY_EXIST: {
    ar: "رمز الترويجي موجود بالفعل",
    en: "This Promo Code Is Already Exist",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  },
  PUBLISHED: {
    ar: "نشر",
    en: "Published",
    tu: "Giriş Yap",
    ru: "Войти",
    fr: "S'identifier",
  }
};


export default words;
