import { redirect } from "react-router-dom";

export function loader() {
  const token = sessionStorage.getItem("token");
  const user = sessionStorage.getItem("user");
  const pathname = window.location.pathname;

  // debugger
  if ((!token || !user) && pathname !== "/auth") {
    return redirect("/auth");
  } else {
    return null;
  }
}

export function tokenLoader() {
  const token = sessionStorage.getItem("token");
  const user = sessionStorage.getItem("user");
  const pathname = window.location.pathname;
  // debugger
  if (token && user && pathname === "/auth") {
    return redirect("/");
  } else {
    return null;
  }
}

export function cardLoader() {
  const cardId = sessionStorage.getItem("cardId");
  if(cardId){
    return redirect("/");
  }else{
    return null;
  }
}
