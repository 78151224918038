import { createSlice } from "@reduxjs/toolkit";

export const users = createSlice({
    name: 'users',
    initialState: {
        deletedUsersIds: JSON.parse(sessionStorage.getItem("deletedUsersIds"))  || [],
    },
    reducers: {
        saveUsersIds: (state, action) => {
            state.deletedUsersIds = action.payload;
            sessionStorage.setItem("deletedUsersIds", JSON.stringify(action.payload));
        },
       clearUsersIds: (state) => {
            state.deletedUsersIds = [];
            sessionStorage.removeItem("deletedUsersIds");
        }
    }
})

export const usersReducer = users.reducer;
export const { saveUsersIds, clearUsersIds } = users.actions;