import { useSelector } from "react-redux";
import words from "../../assets/words";
import classes from './Error.module.scss'
import logo from '../../assets/images/logo.png'

const Error = () => {
    const language = useSelector(state => state.auth.language);

    return (
        <div className={classes.content}>
            <img src={logo} alt='logo pic'/>
            <p>{words.ERROR[language]}</p>
            <p>{words.ERROR_MESSAGE[language]}</p>
        </div>
    )
}

export default Error;