import { createSlice } from "@reduxjs/toolkit";

export const auth = createSlice({
    name: 'authx',
    initialState: {
        language: sessionStorage.getItem('language') || 'en',
        token: sessionStorage.getItem('token') || null,
        user: JSON.parse(sessionStorage.getItem('user')) || null,
        organization: JSON.parse(sessionStorage.getItem('organization')) || null,
        email: sessionStorage.getItem('email') || null
    },
    reducers: {
        saveLanguage: (state, action) => {
            state.language = action.payload.language;
            sessionStorage.setItem('language', action.payload.language);
        },
        saveEmail:(state,action) => {
            state.email = action.payload.email
            sessionStorage.setItem('email', action.payload.email)
        },
        saveToken: (state, action) => {
            state.token = action.payload;
            sessionStorage.setItem('token', action.payload);
        },
        login: (state, action) => {
            const data= action.payload.user
            const userData = {
                id: data?._id,
                corporateName: data?.corporateName,
                corporateSize: data?.corporateSize,
                countryCode: data?.countryCode,
                email: data?.email,
                fullName: data?.fullName,
                industry: data?.industry,
                isCompletedInformation: data?.isCompletedInformation,
                isEmailVerified: data?.isEmailVerified,
                phoneNumber: data?.phoneNumber,
                position: data?.position,
                profilePictureUrl: data?.profilePictureUrl,
                }
            state.user = userData;
            const userJson = JSON.stringify(userData);
            sessionStorage.setItem('user', userJson);

            state.token = action.payload.token;
            sessionStorage.setItem('token', action.payload.token);
        },

        logout: (state) => {
            state.user = null;
            state.token = null;
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('token');
        },
        saveBranchData: (state, action) => {
            const orgData = {
                providerId: action.payload.providerId,
                logoUrl: action.payload.logoUrl,
                branchId: action.payload.branchId,
                branchType: action.payload.branchType,
                name: action.payload.name,
                country: action.payload.country,
            }
            state.organization = orgData;
            const orgJson = JSON.stringify(orgData);
            sessionStorage.setItem('organization', orgJson);
        },
    }
})

export const authReducer = auth.reducer;
export const { saveLanguage, login, logout, saveBranchData, saveEmail, saveToken } = auth.actions;