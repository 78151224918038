import { useSelector } from 'react-redux'
import Header from '../general/Header'
import SideMenu from '../general/SideMenu'
import classes from './MainLayout.module.scss'
import { Outlet, useLocation } from "react-router-dom"
import { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import { useQuery } from '@apollo/client'
import { READ_PROMO_CODES } from '../../graphql/main/queries'
const MainLayout = () => {
    const {language} = useSelector(state => state.auth)
    const [showSideBar,setShowSideBar] = useState(false);
    const location = useLocation()

     const {refetch: rerender} = useQuery(
        READ_PROMO_CODES,
        {
          fetchPolicy: "cache-and-network",
          onCompleted: (data) => {
            if (!data?.readPromoCodes?.success) {
              if (data.readPromoCodes.message === "Please login to continue") {
                sessionStorage.clear();
                window.location.reload();
              }
            }
          },
        }
      );

      useEffect(() => {
        rerender()
      },[location])

    return (
        <div className={classes.mainLayout} dir={language === "ar" ? "rtl" : "ltr"}>
            <div className={classes.sideMenu}>
                <SideMenu />
                <Drawer
            open={showSideBar}
            onClose={() => setShowSideBar(false)}
          >
             <SideMenu showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
          </Drawer>
            </div>
            <div className={classes.rightSide}>
                <div className={classes.header}>
                    <Header setShowSideBar={setShowSideBar} />
                </div>
                <div className={classes.main}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default MainLayout