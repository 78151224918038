import { createSlice } from "@reduxjs/toolkit";

export const main = createSlice({
  name: 'main',
  initialState: {
    promotionsFilters: JSON.parse(sessionStorage.getItem("promotionsFilters")) || {
      startDate: null,
      endDate: null,
      status: null,
      packageType:null,
      isAffilate:null
    },
    clientsFilters: JSON.parse(sessionStorage.getItem("clientsFilters")) || {
      status: null,
    },
    ticketsFilters: JSON.parse(sessionStorage.getItem("ticketsFilters")) || {
      startDate: null,
      endDate: null,
      appType: null,
    },
  },
  reducers: {
    savePromotionsFilter: (state, action) => {
      state.promotionsFilters = action.payload;
      sessionStorage.setItem("promotionsFilters", JSON.stringify(action.payload))
    },
    clearPromotionsFilter: (state) => {
      state.promotionsFilters = {
        startDate: null,
        endDate: null,
        status: null,
        packageType:null,
        isAffilate:null
      }
      sessionStorage.removeItem("promotionsFilters")
    },
    saveClientsFilter: (state, action) => {
      state.clientsFilters = action.payload;
      sessionStorage.setItem("clientsFilters", JSON.stringify(action.payload));
    },
    clearClientsFilter: (state) => {
      state.clientsFilters = {
        status: null,
      };
      sessionStorage.removeItem("clientsFilters");
    },
    saveTicketsFilter: (state, action) => {
      state.ticketsFilters = action.payload;
      sessionStorage.setItem("ticketsFilter", JSON.stringify(action.payload));
    },
    clearTicketsFilter: (state) => {
      state.ticketsFilters = {
        startDate: null,
        endDate: null,
        appType: null
      };
      sessionStorage.removeItem("ticketsFilter");
    },
  }
});

export const mainReducer = main.reducer;
export const { savePromotionsFilter, clearPromotionsFilter, saveClientsFilter, clearClientsFilter, saveTicketsFilter,clearTicketsFilter} = main.actions;
