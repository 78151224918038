import { gql } from "@apollo/client";


export const READ_PROMO_CODES = gql `
query ReadPromoCodes($searchInput: String, $page: Int, $isAffilate: Boolean, $status: String, $startDate: Date, $endDate: Date, $packageType: String) {
  readPromoCodes(searchInput: $searchInput, page: $page, isAffilate: $isAffilate, status: $status, startDate: $startDate, endDate: $endDate, packageType: $packageType) {
    code
    success
    message
    data {
      promoCodes {
        id
        code
        name
        active
        status
        packageTypes
        startDate
        expires_at
        created
        percent_off
        amount_off
        durationInMonths
        max_redemptions
        times_redeemed
        pushNotification
        isAffilate
        affilateData {
          percentage
        }
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}
`

export const READ_SUBSCRIBED_USERS = gql `
query ReadSubscribedUsers($searchInput: String, $next: String) {
  readSubscribedUsers(searchInput: $searchInput, next: $next) {
    code
    success
    message
    data {
      id
      email
      name
    }
    next_page
  }
}
`
export const READ_STRIPE_PRODUCTS_DATA = gql `
query ReadStripeProductsData {
  readStripeProductsData {
    code
    success
    message
    data {
      id
      name
      default_price {
        id
        currency
        unit_amount
        interval
        nickname
      }
      description
    }
  }
}
`

export const READ_STRIPE_SUBSCRIPTIONS = gql `
query ReadAllUsersData($searchInput: String, $page: Int) {
  readAllUsersData(searchInput: $searchInput, page: $page) {
    code
    success
    message
    data {
      list {
        _id
        fullName
        email
        UserType
        numberOfUsers
        profilePictureUrl
        createdAt
        phoneNumber
        countryCode
        position
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}
`
export const READ_SUBSCRIPTIONS_DETAILS_CORPORATE = gql `
query ReadSubscriptionDetails($userId: ID) {
  readSubscriptionDetails(userId: $userId) {
    code
    success
    message
    data {
      _id
      userId
      deviceType
      productType
      startDate
      endDate
      isFreeTrial
      isFreeTrialUsed
      isActive
      inActiveReason
      currency
      price
      country
      createdAt
      trialEndDate
      trialStartDate
    }
  }
}
`
export const READ_SUBSCRIPTIONS_DETAILS_MOBILE = gql `
query ReadSubscriptionDetails($userId: ID) {
  readSubscriptionDetails(userId: $userId) {
    code
    success
    message
    data {
      _id
      userId
      deviceType
      productType
      startDate
      endDate
      isFreeTrial
      isFreeTrialUsed
      isActive
      inActiveReason
      currency
      price
      country
      createdAt
      trialEndDate
      trialStartDate
    }
  }
}
`
export const READ_CORPORATE_BUSINESS_CARD = gql `
query ReadCorporateBusinessCards($page: Int!, $userId: ID) {
  readCorporateBusinessCards(page: $page, userId: $userId) {
    code
    success
    message
    data {
      list {
        _id
        email
        phoneNumber
        countryCode
        font
        fontColor
        primaryColor
        secondaryColor
        cardTextAlignment
        logo
        isPro
        socialMediaLinks {
          providerName
          link
          isPublic
        }
        cardImage
        isCorporate
        userId
        cardTheme
        isLocked
        DesignId
        qrColor
        isAdminCard
        assignQr
        updatedAt
        cardID
        qrImage
        cardLink
        createdAt
        isPublic
        languages {
          fullName
          title {
            key
            value
          }
          position
          language
          cardTextAlignment
        }
      }
      pagination {
        totalDocuments
        viewLimit
      }
      statistics {
        numberOfPublicCards
        numberOfPrivateCards
      }
    }
  }
}
`
export const READ_MOBILE_BUSINESS_CARD = gql `
query ReadAllBusinessCards($userId: ID) {
  readAllBusinessCards(userId: $userId) {
    code
    success
    message
    isAbleToAddCard
    data {
      _id
      email
      phoneNumber
      countryCode
      font
      fontColor
      backgroundColor
      primaryColor
      cardTextAlignment
      secondaryColor
      logo
      isPro
      socialMediaLinks {
        providerName
        link
        isPublic
      }
      cardImage
      lowQualityCardImage
      isCorporate
      userId
      cardTheme
      isLocked
      DesignId
      qrColor
      isAdminCard
      assignQr
      updatedAt
      cardID
      qrImage
      lowQualityQrImage
      cardLink
      createdAt
      isPublic
      defaultCard
      languages {
        fullName
        title {
          key
          value
        }
        position
        language
        company
        cardTextAlignment
      }
      tags {
        _id
        tagName
        tagColor
        userId
        schemaVersion
      }
    }
  }
}
`
export const READ_ALL_TICKETS = gql `
query ReadTicketsList($page: Int!, $appType: String, $startDate: Date, $endDate: Date, $searchInput: String) {
  readTicketsList(page: $page, appType: $appType, startDate: $startDate, endDate: $endDate, searchInput: $searchInput) {
    code
    success
    message
    data {
      tickets {
        _id
        customerName
        customerEmail
        customerPhoneNumber
        customerCountryCode
        appType
        ticketId
        description
        isActive
        isDeleted
        createdBy
        updatedBy
        createdAt
        updatedAt
        customerProfilPictureUrl
        reason {
          key
          value
        }
        attachment
      }
      pagination {
        totalDocuments
        viewLimit
      }
    }
  }
}
`

export const READ_WEB_PRICIE = gql `
query ReadWebPricing {
  readWebPricing {
    code
    success
    message
    data
  }
}
`